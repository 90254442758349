<template>
    <div>
        <vx-card :title="$t('opinion.opinion')" class="mb-base items-center">
            <el-tabs v-model="activeName" @tab-click="handleopinionClick">
                <!-- 被邀請 -->
                <el-tab-pane name="first">
                    <!-- title -->
                    <span style="font-size: 16px" slot="label">
                        <i class="el-icon-s-unfold"></i>
                        <i v-if="windowWidth >= 768">{{ $t('opinion.beInvited') }}</i>
                        <i v-else>{{ $t('opinion.beInvitedRWD') }}</i>
                        <el-badge v-show="opinionActiveCount != 0" :value="opinionActiveCount"></el-badge>
                    </span>
                    <vs-row>
                        <!-- 搜尋按鈕 -->
                        <vs-col>
                            <!-- 被邀請會診判斷類型為1  -->
                            <opinion-filter OpinionFilter="1"></opinion-filter>
                        </vs-col>
                        <!-- 被邀請會診項目 -->
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                            <!-- 螢幕寬度大於等於768 -->
                            <vs-list v-if="myopinion.length != 0 && windowWidth >= 768">
                                <vs-list-item :style="item.status == 0 ? 'color: black;' : 'color: grey;'" :title="'From: ' + item.dname + ' ' + getTypeName(item.type)" :subtitle="item.message" v-for="(item, index) in myopinion" :key="index">
                                    <vs-row>
                                        <vs-col class="my-3" vs-type="flex" vs-justify="center" vs-align="center">
                                            <p style="font-size: 16px">{{ item.created_at }}</p>
                                            <vs-button class="mx-2 cd-button-4w" type="border" @click="goDetail(item, 1)">{{ $t('opinion.detail') }}</vs-button>
                                            <el-select v-model="item.status" :disabled="item.status == 1" style="width: 120px" @change="sendStatus(item)">
                                                <el-option :label="$t('opinion.process')" :value="0"></el-option>
                                                <el-option :label="$t('opinion.finish')" :value="1"></el-option>
                                            </el-select>
                                        </vs-col>
                                    </vs-row>
                                </vs-list-item>
                            </vs-list>
                            <!-- 螢幕寬度小於768 -->
                            <vs-list v-else-if="myopinion.length != 0 && windowWidth < 768">
                                <vs-list-item :style="item.status == 0 ? 'color: black;' : 'color: grey;'" :title="'From: ' + item.dname + ' ' + getTypeName(item.type)" :subtitle="item.message" v-for="(item, index) in myopinion" :key="index">
                                    <vs-row class="my-2">
                                        <vs-col class="mb-2" vs-type="flex" vs-justify="flex-end" vs-align="center">
                                            <p style="font-size: 16px">{{ item.created_at }}</p>
                                        </vs-col>
                                        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                                            <vs-button class="mx-2 cd-button-4w" type="border" @click="goDetail(item, 1)">{{ $t('opinion.detail') }}</vs-button>
                                            <el-select v-model="item.status" :disabled="item.status == 1" style="width: 100px" @change="sendStatus(item)">
                                                <el-option :label="$t('opinion.process')" :value="0"> </el-option>
                                                <el-option :label="$t('opinion.finish')" :value="1"> </el-option>
                                            </el-select>
                                        </vs-col>
                                    </vs-row>
                                </vs-list-item>
                            </vs-list>
                            <!-- 沒資料 -->
                            <vs-col class="mt-10" vs-type="flex" vs-justify="center" v-if="myopinion.length == 0">
                                <h3 class="justify-content-between">{{ $t('opinion.noData') }}</h3>
                            </vs-col>
                        </vs-col>
                    </vs-row>
                    <!-- 新增分頁 -->
                    <vs-row>
                        <vs-col class="mb-4 mt-2" vs-type="flex" vs-justify="center">
                            <vs-pagination :max="windowWidth < 768 ? 5 : 7" :total="Math.ceil(myopinionTotal / 10)" v-model="myOpinionPage" @change="getMyOpinions(myOpinionPage)"></vs-pagination>
                        </vs-col>
                    </vs-row>
                </el-tab-pane>
                <!-- 邀請 -->
                <el-tab-pane name="second">
                    <!-- title -->
                    <span style="font-size: 16px" slot="label">
                        <i class="el-icon-s-unfold"></i>
                        <i v-if="windowWidth >= 768">{{ $t('opinion.invite') }}</i>
                        <i v-else>{{ $t('opinion.inviteRWD') }}</i
                        >{{ '(' + unFinishOpinion + ')' }}
                    </span>
                    <vs-row>
                        <!-- 搜尋按鈕 -->
                        <vs-col>
                            <!--邀請會診判斷類型為2  -->
                            <opinion-filter OpinionFilter="2"></opinion-filter>
                        </vs-col>
                        <!-- 邀請會診項目 -->
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                            <!-- 螢幕寬度大於等於768 -->
                            <vs-list v-if="opinion.length != 0 && windowWidth >= 768">
                                <vs-list-item :style="item.status == 0 ? 'color: black;' : 'color: grey;'" :title="'To: ' + item.dname + ' ' + getTypeName(item.type)" :subtitle="item.opinion" v-for="(item, index) in opinion" :key="index">
                                    <vs-col class="my-2" vs-type="flex" vs-justify="center" vs-align="center">
                                        <p class="cd-p-normal mr-4">{{ item.created_at }}</p>
                                        <div :style="item.status != 0 ? 'buttonPrimary' : 'buttonGrey'">
                                            <vs-button class="mx-1 cd-button-2w" type="border" :color="item.status == 0 ? 'primary' : 'grey'" @click="goDetail(item, 2)">{{ $t('opinion.content') }}</vs-button>
                                            <vs-button class="mx-1 cd-button-2w" type="border" :color="item.status == 0 ? 'primary' : 'grey'" @click="goemr2(item.pid)">{{ $t('opinion.mr') }}</vs-button>
                                        </div>
                                        <p class="mx-2" v-if="item.status == 0">{{ $t('opinion.process') }}</p>
                                        <p class="mx-4" v-else-if="item.status == 1">{{ $t('opinion.finish') }}</p>
                                    </vs-col>
                                </vs-list-item>
                            </vs-list>
                            <!-- 螢幕寬度小於768 -->
                            <vs-list v-else-if="opinion.length != 0 && windowWidth < 768">
                                <vs-list-item :style="item.status == 0 ? 'color: black;' : 'color: grey;'" :title="'To: ' + item.dname + ' ' + getTypeName(item.type)" :subtitle="item.opinion" v-for="(item, index) in opinion" :key="index">
                                    <vs-row class="my-2">
                                        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                                            <p class="cd-p-normal">{{ item.created_at }}</p>
                                        </vs-col>
                                        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                                            <vs-button class="mx-1 cd-button-2w" type="border" @click="goDetail(item, 2)">{{ $t('opinion.content') }}</vs-button>
                                            <vs-button class="mx-1 cd-button-2w" type="border" @click="goemr2(item.pid)">{{ $t('opinion.mr') }}</vs-button>
                                            <p class="mx-2" v-if="item.status == 0">{{ $t('opinion.process') }}</p>
                                            <p class="mx-4" v-else-if="item.status == 1">{{ $t('opinion.finish') }}</p>
                                        </vs-col>
                                    </vs-row>
                                </vs-list-item>
                            </vs-list>
                            <!-- 沒資料 -->
                            <vs-col class="mt-10" vs-type="flex" vs-justify="center" v-else>
                                <p class="justify-content-between">{{ $t('opinion.noData') }}</p>
                            </vs-col>
                        </vs-col>
                    </vs-row>
                    <!-- 新增分頁 -->
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="center">
                            <vs-pagination :max="windowWidth < 768 ? 5 : 7" :total="Math.ceil(opinionTotal / 10)" v-model="OpinionPage" @change="getOpinions(OpinionPage)"></vs-pagination>
                        </vs-col>
                    </vs-row>
                </el-tab-pane>
            </el-tabs>
        </vx-card>
    </div>
</template>
<script>
import { updateOpinionStatus, updateBadge } from '@/api/user'
import OpinionFilter from './OpinionFilter.vue'
export default {
    components: { OpinionFilter },
    computed: {
        totalCountExceptMember() {
            return this.$store.getters.totalCountExceptMember
        },
        unFinishOpinion() {
            return this.$store.getters.unFinishOpinion
        },
        unFinishOpinionInTotal() {
            return this.$store.state.unFinishOpinionInTotal
        },
        opinionActiveCount() {
            return this.$store.getters.opinionActiveCount
        },
        opinionActiveCountInTotal() {
            return this.$store.state.opinionActiveCountInTotal
        },
        opinion() {
            return this.$store.getters.opinion
        },
        myopinion() {
            return this.$store.getters.myopinion
        },
        opinionTotal() {
            return this.$store.state.opinionTotal
        },
        myopinionTotal() {
            return this.$store.state.myopinionTotal
        },
        opinionPage() {
            return this.$store.state.OpinionPage
        },
        myopinionPage() {
            return this.$store.state.myOpinionPage
        },
        opFilterFlag() {
            return this.$store.state.opFilterFlag
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
    },
    async mounted() {
        let _self = this
        // 會診tab狀態呈現
        if (localStorage.getItem('component_tab') != null) {
            var component_tab = JSON.parse(localStorage.getItem('component_tab'))
            if (component_tab['opinion_component'] != null) {
                _self.init_preopinionClick()
            }
        }
        _self.$store.commit('SET_OPFILTER_FLAG', false)
    },
    data() {
        return {
            activeName: 'first',
            myOpinionPage: 1,
            OpinionPage: 1,
        }
    },
    methods: {
        // 紀錄點到哪個tab
        handleopinionClick(tab, event) {
            var all_component_tab = { opinion_component: tab.name }
            localStorage.setItem('component_tab', JSON.stringify(all_component_tab))
        },
        // 預先載入會診tab
        init_preopinionClick() {
            var component_tab = JSON.parse(localStorage.getItem('component_tab'))
            this.activeName = component_tab['opinion_component']
        },
        // 取所有類型的文字
        getTypeName(value) {
            const statusMap = {
                0: '[' + this.$t('opinion.generalPhoto') + ']',
                1: '[' + this.$t('opinion.generalReport') + ']',
                2: '[' + this.$t('opinion.dicom') + ']',
                3: '[' + this.$t('opinion.funduscopy') + ']',
                4: '[' + this.$t('opinion.ultrasonograph') + ']',
                5: '[' + this.$t('opinion.ecg') + ']',
                6: '[' + this.$t('opinion.faceDiagnosticScope') + '-' + this.$t('opinion.ear') + ']',
                7: '[' + this.$t('opinion.faceDiagnosticScope') + '-' + this.$t('opinion.nasal') + ']',
                8: '[' + this.$t('opinion.faceDiagnosticScope') + '-' + this.$t('opinion.throat') + ']',
                9: '[' + this.$t('opinion.faceDiagnosticScope') + '-' + this.$t('opinion.oral') + ']',
                10: '[' + this.$t('opinion.faceDiagnosticScope') + '-' + this.$t('opinion.skin') + ']',
                11: '[' + this.$t('opinion.video') + ']',
                12: '[' + this.$t('opinion.phono') + ']',
                13: '[' + this.$t('opinion.ITRI_AI') + ']',
            }
            return statusMap[value]
        },
        // 檢視內容
        goDetail(item, opinionfilter) {
            this.$store.commit('SET_OPINION_STATE', true)
            this.$store.commit('SET_OPINION_ID', item.id)
            this.$store.commit('SET_OPINION_TYPE', item.type)
            this.$store.commit('SET_OPINION_STATUS', item.status)
            this.$store.commit('SET_OPINION_ALL_DATA', item)
            // 工研院眼底鏡AI type=13
            if (item.type == 13) {
                this.$router.push('/funduscopy/' + opinionfilter + '/' + item.id)
            } else {
                this.$router.push('/histemplate/' + opinionfilter + '/' + item.id)
            }
        },
        // 被邀請 進行中 改成 完成
        sendStatus(item) {
            let _self = this
            updateOpinionStatus(item).then((res) => {
                if (res.data.status == 'OK') {
                    _self.$vs.notify({
                        color: 'grey',
                        title: _self.$t('message.update_success'),
                        position: 'top-center',
                    })
                    _self.getOpinions(1)
                    _self.getMyOpinions(1)
                }
            })
        },
        // 被邀請 換頁
        getOpinions(OpinionPage = 1) {
            this.$store.commit('SET_OPINION_PAGE', OpinionPage)
            window.scrollTo(0, 0)
        },
        // 邀請 換頁
        getMyOpinions(myOpinionPage = 1) {
            this.$store.commit('SET_MYOPINION_PAGE', myOpinionPage)
            window.scrollTo(0, 0)
        },
        // 病歷連結
        goemr2(pid) {
            this.$router.push('/emr2/' + pid + '/0')
        },
    },
    watch: {
        // 被邀請 頁數改變 就取資料
        myopinionPage(newValue) {
            var _self = this
            //無搜尋
            if (_self.opFilterFlag == false) {
                _self.$store.dispatch('fetchMyOpinion', newValue)
                //有搜尋
            } else if (_self.opFilterFlag == true) {
                _self.$store.dispatch('dateSearchMyOpinion')
            }
        },
        // 邀請 頁數改變 就取資料
        opinionPage(newValue) {
            var _self = this
            //無搜尋
            if (_self.opFilterFlag == false) {
                _self.$store.dispatch('fetchOpinion', newValue)
                //有搜尋
            } else if (_self.opFilterFlag == true) {
                _self.$store.dispatch('dateSearchOpinion')
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        var _self = this
        this.$store.commit('SET_OPINION_ACTIVE_COUNT', _self.opinionActiveCountInTotal)
        this.$store.commit('SET_OPINION_UNFINISH_TOTAL', _self.unFinishOpinionInTotal)
        next()
    },
    beforeDestroy: function () {
        updateBadge(this.totalCountExceptMember)
    },
}
</script>
<style lang="scss" scoped>
.opinionStatus {
    width: 30%;
}
// 已完成會診項目按鈕
.buttonGrey {
    color: grey !important;
    border-color: grey !important;
}
// 進行中會診項目按鈕
.buttonPrimary {
    color: #003f8e !important;
    border-color: #003f8e !important;
}
// 手機版排版
@media screen and (max-width: 576px) {
    .vx-row {
        float: none;
    }
    .vs-list--item {
        display: inline-block;
    }
}
@media screen and (max-width: 400px) {
    .vx-row {
        float: none;
    }
    .vs-list--item {
        display: inline-block;
    }
}
</style>
